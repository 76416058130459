
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';

import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import ChannelService from '../../services/channel.service';
import CompaniesService from '../../../Companies/services/companies.service';
import { Channel } from '../../entities/channel.entity';
import { Company } from '../../../Companies/entities/company.entity';
import { Order } from '../../../Orders/entities/order.entity';
import OrderService from '../../../Orders/services/order.service';

@Component({ components: { AlertError, DeleteDialog } })
export default class ChannelCreateComponent extends Vue {
  public $refs: any;

  private channel: Channel = new Channel();

  private companies: Company[] = [];

  private statuses: any[] = [];

  private showDialogDelete: boolean = false;

  private errors: any[] = [];

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private setShowDialogDelete() {
    this.showDialogDelete = true;
  }

  private async getChannelById() {
    const { id } = this.$route.params;
    const channel = await ChannelService.getChannelById(id);
    this.channel = new Channel(channel);
    this.$loader.close();
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteItem(channel: Channel) {
    this.channel
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Motivo excluído com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao cadastrar o canal de venda',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private async save() {
    this.channel
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Motivo cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'channel-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar channel',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }

  private async edit() {
    this.channel
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Motivo alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'channel-list',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private async goToList() {
    this.$router.push({
      name: 'channel-list',
    });
  }

  private async setCompanyId(companyId: string) {
    this.channel.companyId = companyId;
  }

  private async getCompanies() {
    const companies: Company[] = await CompaniesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private created() {
    this.getCompanies();
    if (this.isEdit) {
      this.getChannelById();
    }
  }
}
