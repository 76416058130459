import store from '@/store';
import ChannelService from '../services/channel.service';

export class Channel {
  public id: string = '';

  public description: string = '';

  public active: boolean | number = false;

  public has_relationship: boolean | number = false;

  public companyId: string = '';

  public company: any;

  constructor(data: any = {}) {
    this.id = data.id;
    this.description = data.description || '';
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.company = data.company;
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
      company_id: this.companyId,
    };
  }

  public save() {
    return ChannelService.create(this.format());
  }

  public update() {
    return ChannelService.update(this.format());
  }

  public delete() {
    return ChannelService.delete(this.id);
  }
}
