import { AxiosResponse } from 'axios';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { Channel } from '../entities/channel.entity';

class ChannelService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: Channel) {
    return http.post('/channel', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: Channel) {
    return http.put(`/channel/${payload.id}`, payload).then(({ data }: AxiosResponse) => data);
  }

  public delete(channelId: string) {
    return http.delete(`/channel/${channelId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllChannels(company_id?: string) {
    return http
      .get('/channel', {
        params: {
          company_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getAllChannelsPaginated() {
    return http
      .get('/channel', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getChannelById(id: string) {
    return http.get(`/channel/${id}`).then(({ data }: AxiosResponse) => data.data);
  }
}
const simplePaginationService = new SimplePagination();
export default new ChannelService(simplePaginationService);
